// serif
// @import url('https://fonts.googleapis.com/css?family=Caudex:400,400i,700,700i&display=swap');

@import url('https://fonts.googleapis.com/css?family=DM+Serif+Display:400,400i&display=swap');

// sans-serif
@import url('https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i&display=swap');

// @import url('https://fonts.googleapis.com/css?family=Timmana&display=swap');

$nav-height: 60px;
$footer-height: 160px;
$gray-1: #c8c8c8;
$gray-2: #969696;
$gray-3: #646464;
$black: #323232;

$serif: 'DM Serif Display', serif;
$sans-serif: 'Lato', sans-serif;

html {
  font-size: 18px;
}

body {
  color: $black;
  font-family: $sans-serif;
  font-weight: 300;
}

p {
  font-size: 1rem;
  line-height: 2.2rem;
}

a {
  color: $black;
  &:hover {
    color: $gray-2;
    text-decoration: none;
  }
}

.serif {
  font-family: $serif;
}

.sans-serif {
  font-family: $sans-serif;
}

@media (min-width: 1400px) {
  .container {
    max-width: 1360px;
  }
}

.nav-wrapper {
  position: fixed;
  z-index: 1;
}

.nav-open {
  cursor: pointer;
  position: absolute;
}

.nav-close {
  cursor: pointer;
  position: absolute;
  z-index: 9;
  color: white;
}

.main-nav {
  color: white;
  height: 100vh;
  position: absolute;
  background: $gray-2;
  width: 100vw;
  text-align: center;
  transition: all 0.4s ease-in-out;
  z-index: 2;

  @media screen and (min-width: 768px) {
    transition: all 0.5s ease-in-out;
  }

  &.inactive {
    animation-name: nav-background-out;
    animation-duration: 0.6s;
    display: none;
    transform: translate3d(-100%, 0, 0);
    transition: visibility 0.5s, opacity 0.6s linear;
    visibility: hidden;
  }

  &.active {
    display: block;
    animation-name: nav-background-in;
    animation-duration: 1s;
    transform: translate3d(0, 0, 0);
    opacity: 1;
    visibility: visible;
  }

  @keyframes nav-background-in {
    from {
      background-color: $black;
    }
    to {
      background-color: $gray-2;
    }
  }

  @keyframes nav-background-out {
    from {
      background-color: $gray-2;
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
    to {
      background-color: white;
      transform: scale3d(2, 2, 2);
      opacity: 0;
    }
  }

  a {
    color: white;
  }
}

.menu-link {
  &:hover {
    text-decoration: none;
  }

  p {
    font-size: 2rem;
    text-transform: uppercase;
    letter-spacing: 3px;
  }
}

.kathryn-hero {
  background: white;
  height: 100vh;
  min-height: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 10rem;
  padding: 0 20px 120px 20px;

  @media screen and (min-width: 768px) {
    padding: 0 20px 120px 20px;
  }

  img {
    width: 180px;
    animation: 3s ease-out 0s 1 heroImageIn;

    @media (min-width: 768px) {
      width: 240px;
    }
  }

  @keyframes heroImageIn {
    0% {
      opacity: 0;
    }

    30% {
      transform: scale3d(1.6, 1.6, 1.6);
      opacity: 0;
    }
    100% {
      transform: scale3d(1, 1, 1);
      opacity: 1;
    }
  }

  h1 {
    font-family: $serif;
    font-weight: 400;
    font-size: 3rem;
    line-height: 0.9;

    margin-bottom: 1rem;
    text-align: center;
    animation-name: hero-header-in;
    animation-duration: 1s;
    @media (min-width: 768px) {
      line-height: 1.2;
    }
  }

  h2 {
    font-size: 1.2rem;
    font-weight: 300;
    animation-name: hero-header-in;
    animation-duration: 1.4s;
  }

  @keyframes hero-header-in {
    0% {
      opacity: 0;
      transform: translate3d(0, 40%, 0);
    }

    20% {
      opacity: 0;
      transform: translate3d(0, 40%, 0);
    }

    100% {
      opacity: 1;
      transform: translate3d(0, 0%, 0);
    }
  }
}

.see-work {
  color: $black;
  font-size: 1rem;
  position: absolute;
  bottom: 20px;
  // this delays visibility so that opacity's animation finishes a second before to avoid pop-out
  transition: visibility 2s, opacity 1s ease-in-out;
  animation: 3s ease-out 0s 1 seeWorkIn;
  opacity: 1;
  visibility: visible;
  @media (min-width: 768px) {
    right: 10px;
  }

  &:hover {
    color: inherit;
    text-decoration: none;
  }

  &.fade-away {
    visibility: hidden;
    opacity: 0;
  }
}

@keyframes seeWorkIn {
  0% {
    opacity: 0;
  }

  60% {
    transform: translate3d(-50%, 0, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

p {
  font-size: 1rem;
}

#second-section {
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row-reverse;
  }
}

.recent-work {
  // flex-direction: column-reverse;
  padding: 0 30px;
  @media (min-width: 768px) {
    padding: 0 40px 30px 20px;
  }
  h3 {
    font-size: 1rem;
    font-weight: 300;
    margin-bottom: 4px;
  }

  h4 {
    font-weight: 400;
    font-size: 1rem;
  }

  p {
    margin: 0 0 4px 0;
  }

  a {
    margin-top: 20px;
  }
}

.recent-image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 500px;
  overflow: hidden;
}

.kathryn-home-background {
  height: 500px;
  background: url('https://kathryn-goshorn-assets.s3.amazonaws.com/kathryn_a.jpg') -400px -120px;
  background-size: 1600px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  @media (min-width: 768px) {
    background: url('https://kathryn-goshorn-assets.s3.amazonaws.com/kathryn_a.jpg')
      center;
    flex-direction: row;

    height: 600px;
    background-size: cover;
  }
}

.kathryn-home-info {
  background: rgba(15, 15, 15, 0.8);
  color: white;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 50px;
  text-align: center;
  @media (min-width: 768px) {
    text-align: left;
    height: auto;
  }

  h3 {
    font-family: serif;
  }

  h4 {
    font-weight: 400;
    font-size: 1rem;
  }

  p {
    margin-bottom: 0;
  }
  
  a {
    color: white;
    &:hover {
      text-decoration: none;
    }
  }
}

.kathryn-about-background {
  height: 500px;
  background: url('https://kathryn-goshorn-assets.s3.amazonaws.com/kathryn_d.jpg') -380px -40px;
  background-size: 250%;
  @media (min-width: 768px) {
    background: url('https://kathryn-goshorn-assets.s3.amazonaws.com/kathryn_d.jpg')
      center;
    background-size: cover;
  }
}

.kathryn-about-info {
  padding-left: 50px;
  max-width: 700px;
}

.exhibition-row {
  display: flex;
  margin-bottom: 20px;

  &.header {
    p {
      font-weight: 400;
      text-transform: uppercase;
      font-size: 0.8rem;
    }
  }

  p {
    margin-bottom: 0;
    line-height: 1.4rem;
    padding-right: 12px;
  }

  .exhibition-name {
    display: inline-block;
    flex: 1 1 300px;
  }

  .exhibition-gallery {
    display: inline-block;
    flex: 1 1 300px;
  }

  .exhibition-year {
    display: inline-block;
    flex: 0 0 52px;
    padding: 0;
  }
}

.contact-image-wrapper {
  padding-bottom: 0;

  @media (min-width: 768px) {
    padding-bottom: 60px;
  }
  img {
    width: 200px;
  }
}

.contact-info-wrapper {
  display: flex;
  align-items: space-between;
  justify-content: center;
  flex-direction: column;
  height: 20vh;
  padding-bottom: 20px;

  @media (min-width: 768px) {
    height: 60vh;
    min-height: 400px;
  }

  .contact-row {
    display: flex;

    p {
      margin-bottom: 0;
    }

    .contact-type {
      display: inline-block;
      flex: 1 1 300px;
      text-align: left;
    }

    .contact-info {
      display: inline-block;
      flex: 0 0 80px;
      font-weight: 700;
    }
  }
}

.contact-note {
  align-self: baseline;
  text-align: center;

  @media (min-width: 768px) {
    text-align: left;
  }
}

.portfolio {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  width: 100%;
  
  &.see-more {
    justify-content: center;
    @media screen and (min-width: 768px) {
      flex-wrap: nowrap;
    }
  }
  
  li {
    img {
      max-height: 300px;
      height: 40vw;
      margin: 10px;
      @media screen and (min-width: 768px) {
        height: 20vw;
      }
    }
  }
}

.work-thumbnail {
  max-width: 300px;
}

.work-top-row {
  @media screen and (min-width: 768px) {
    max-width: 1140px;
    margin: auto;
    flex-direction: row-reverse !important;
    display: flex;
    flex-wrap: wrap;
  }
  .work-info {
    padding: 0 32px;
    text-align: center;
    @media screen and (min-width: 768px) {
      text-align: left;
    }

    .work-subtitle {
      font-style: italic;
      font-size: 1.2rem;
      margin: 0 0 28px 0;
    }
    p {
      line-height: 1.4rem;
      margin: 16px 0;
    }
  }

  .work-main-image {
    text-align: center;
    @media screen and (min-width: 768px) {
      text-align: right;
    }
  }

  h1 {
    font-family: $serif;
    // font-weight: 400;
    font-size: 3rem;
  }
}

.work-images {
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    max-height: 800px;
  }
}

.full-image-container {
  max-height: 600px;
  min-height: 400px;
  height: 30vh;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.footer {
  background: $gray-1;
  color: $black;
  height: $footer-height;
  padding: 36px;
  text-align: center;
  p {
    letter-spacing: 1px;
    line-height: 1.6rem;
    font-weight: 300;
    font-size: 0.8rem;
    margin-bottom: 0;
  }
}

.page-wrapper {
  min-height: calc(100vh - #{$footer-height});
}
